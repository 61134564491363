<template>
  <div>
    <v-menu
      content-class="m-0 p-0"
      transition="scale-transition"
      offset-y
      bottom
      nudge-bottom="5"
      :left="!$vuetify.rtl"
      :right="$vuetify.rtl"
      rounded
      max-height="75%"
    >
      <template v-slot:activator="{ on, attrs }">
        <button
          v-on="on"
          v-bind="attrs"
          class="poppins btn btn--export-filter px-5"
        >
          <span class="svg-icon">
            <v-icon size="18">mdi-dns</v-icon>
          </span>
          <span>Actions</span>
          <span class="svg-icon mr-0 ml-2">
            <v-icon size="18">mdi-chevron-down</v-icon>
          </span>
        </button>
      </template>
      <div class="py-1 w-175px bg-white px-3">
        <div
          class="drop-item poppins px-3 rounded-lg cursor-pointer"
          v-for="(action, index) in types"
          :key="index"
          @click="() => handleClick(action.func)"
        >
          <div
            class="py-2 w-100 text-left drop-text poppins font-size-sm font-weight-medium"
            :class="[
              { 'mt-2': index === 0 },
              { 'mb-2': index === types.length - 1 },
            ]"
          >
            {{ action.name }}
          </div>
        </div>
      </div>
    </v-menu>
    <CycleCountModal
      ref="cycleCountModal"
      :page-loading="pageLoader"
      :refresher="refresher"
    />
    <CloseCycleCount
      ref="closeCycleCount"
      :page-loading="pageLoader"
      :refresher="refresher"
    />
  </div>
</template>

<script>
import SwalService from "@/core/services/swal.service";
import CycleCountModal from "@/own/components/warehouseManagement/cycleCounts/CycleCountModal.vue";
import CloseCycleCount from "@/own/components/warehouseManagement/cycleCounts/CloseCycleCount.vue";
import ApiService from "@/core/services/api.service";
import { CREATE_CYCLE_COUNT } from "@/core/services/store/cycleCounts.module";

export default {
  name: "ActionsButton",
  components: { CycleCountModal, CloseCycleCount },
  props: [
    "permissions",
    "selectedItems",
    "refresher",
    "pageLoader",
    "clearSelected",
  ],
  methods: {
    handleClick(func) {
      this[func]();
    },
    createCycleCount() {
      this.pageLoader(true);
      const payload = {
        warehouse_id: this.$store.getters.getSelectedWarehouse,
      };
      this.$store.dispatch(CREATE_CYCLE_COUNT, payload).then(() => {
        this.$refs.cycleCountModal.toggleModal();
        this.pageLoader(false);
      });
    },
    closeCycleCount() {
      this.$refs.closeCycleCount.toggleModal();
    },
    cancelCycleCount() {
      if (this.selectedItems.length) {
        SwalService.warningConditionMessage(
          {
            html: SwalService.messages.you_wont_be_able_to_revert_this,
          },
          () => {
            this.pageLoader(true);
            ApiService.post(
              "/warehouse_management/custom/cycle_counts/cancel",
              {
                ids: this.selectedItems.map((item) => item.id),
              }
            )
              .then(async () => {
                SwalService.successMessage({
                  title: SwalService.titles.success,
                });
                await this.refresher();
                this.clearSelected();
              })
              .catch(() => {
                this.pageLoader(false);
              });
          }
        );
      } else {
        SwalService.warningMessage({
          title: SwalService.messages.please_select_at_least_one_item,
        });
      }
    },
  },
  computed: {
    types: function() {
      const types = [
        {
          index: 1,
          name: this.$t("create"),
          perm: "create",
          func: "createCycleCount",
        },
        {
          index: 2,
          name: this.$t("close"),
          perm: "create",
          func: "closeCycleCount",
        },
        {
          index: 3,
          name: this.$t("cancel"),
          perm: "update",
          func: "cancelCycleCount",
        },
      ];

      return types.filter((item) => this.permissions(item.perm));
    },
  },
};
</script>

<style scoped>
.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}

.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}
</style>
