<template>
  <div class="p-4 bg-white rounded-lg" v-if="isDataLoaded">
    <DataTable
      :table_state="table_state"
      :store_names="store_names"
      :isFilterRequiredForExport="true"
      :isWarehouseRequired="true"
      :skipAddButton="true"
      :overrideActionsWith="actions"
      :recordDetailComponent="cycleCountDetails"
      @makeRecount="makeRecount"
      @assignUser="assignUser"
      @exportTasks="exportTasks"
      @closeTask="closeTask"
      @makeCount="makeCount"
      @confirmCount="confirmCount"
    >
      <template
        v-slot:topActions="{
          permissions,
          updateTable,
          pageLoader,
          selectedItems,
          clearSelected,
        }"
      >
        <div
          class="mr-md-2 mr-sm-1 mb-1 mr-0 pa-0"
          :class="{ 'col-6 px-8 mt-3': $vuetify.breakpoint.xsOnly }"
        >
          <ActionsButton
            :permissions="permissions"
            :refresher="updateTable"
            :pageLoader="pageLoader"
            :selectedItems="selectedItems"
            :clearSelected="clearSelected"
          />
        </div>
      </template>
      <template
        v-slot:rowActionsDropdown="{
          permissions,
          pageLoader,
          item,
          updateTable,
        }"
      >
        <v-btn
          class="d-block w-100"
          :disabled="!item.extra_data.detail.actions.download_cc_document"
          text
          @click="
            () => {
              handleDownloadAction(item);
            }
          "
          >Download document
        </v-btn>
      </template>
    </DataTable>
    <AssignUser ref="assignUser" :page-loading="setPageLoading" />
    <!-- ScanItem with dynamic form fields -->
    <ActionsComponent ref="scanItem" />
    <!-- confirm scanned items -->
    <ConfirmCycleCount
      ref="cycleCount"
      :item="item"
      :headers="confirmItemsHeaders"
      title="cycle count"
      responseObject="scanned_items"
      getItemsUrl="cycle_counts/all-scanned-items"
      confirmItemsUrl="cycle_counts/confirm-scanned-items"
      removeItemsUrl="cycle_counts/remove-scanned-items"
      :updateTable="updateTableData"
    />
    <DownloadDocument
      ref="downloadComponent"
      :pageLoading="setPageLoading"
      :functionForDownload="downloadCCDocument"
    />
  </div>
</template>

<script>
import DataTable from "@/own/components/DataTable.vue";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
  SET_ACTION_TYPE,
  UPDATE_EXPORT_TASKS,
  CLOSE_CYCLE_COUNT,
} from "@/core/services/store/cycleCounts.module";
// import CycleCountPanel from "@/own/components/warehouseManagement/cycleCounts/CycleCountPanel.vue";
import ActionsButton from "@/own/components/warehouseManagement/cycleCounts/ActionsButton.vue";
import AssignUser from "@/own/components/warehouseManagement/cycleCounts/AssignUser.vue";
import CycleCountDetails from "@/own/components/warehouseManagement/cycleCounts/CycleCountDetails.vue";
import ActionsComponent from "@/own/components/warehouseManagement/cycleCounts/ActionsComponent.vue";
import ConfirmCycleCount from "@/own/components/warehouseManagement/cycleCounts/ConfirmCycleCount.vue";
import Swal from "sweetalert2";
import SwalService from "@/core/services/swal.service";
import ApiService from "@/core/services/api.service";
import DownloadDocument from "@/own/components/warehouseManagement/cycleCounts/DownloadDocument.vue";

export default {
  name: "CycleCounts",
  components: {
    DataTable,
    // CycleCountPanel,
    ActionsButton,
    AssignUser,
    ActionsComponent,
    ConfirmCycleCount,
    DownloadDocument,
  },
  data: () => ({
    table_state: {
      UPDATE_TABLE_STRUCTURE: UPDATE_TABLE_STRUCTURE,
      UPDATE_TABLE_DATA: UPDATE_TABLE_DATA,
      SET_TABLE_SORTBY: SET_TABLE_SORTBY,
      SET_TABLE_FILTER: SET_TABLE_FILTER,
      SET_TABLE_PERPAGE: SET_TABLE_PERPAGE,
      SET_TABLE_PAGE: SET_TABLE_PAGE,
      EXPORT_TABLE_DATA: EXPORT_TABLE_DATA,
    },
    store_names: {
      getTableFilters: "getCYCLECOUNTSTableFilters",
      getTableState: "getCYCLECOUNTSTableState",
      getTableData: "getCYCLECOUNTSTableData",
      getTableHeaders: "getCYCLECOUNTSTableHeaders",
      getTableProperties: "getCYCLECOUNTSTableProperties",
      getTableExportUrl: "getCYCLECOUNTSExportUrl",
      getTablePermissions: "getCYCLECOUNTSTablePermissions",
    },
    cycleCountDetails: CycleCountDetails,
    confirmItemsHeaders: [
      { text: "Package code", value: "package_code" },
      { text: "SKU", value: "sku_barcode" },
      { text: "Description", value: "description" },
      { text: "UOM", value: "uom" },
      { text: "Batch no.", value: "batch_number" },
      { text: "Serial no.", value: "serial_no" },
      { text: "Scanned qty", value: "scanned_qty" },
      { text: "Action", value: "action" },
    ],
    item: null,
  }),
  methods: {
    handleDownloadAction(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.downloadComponent.toggleModal();
    },
    downloadCCDocument(data) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post(
        "/warehouse_management/custom/cycle_counts/download_cc_document",
        {
          ...data,
          warehouse_id: this.selectedWarehouse,
        }
      )
        .then(({ data }) => {
          const newTab = window.open(); // Open a new blank tab
          newTab.location.href = data.path; // Redirect to the file URL
        })
        .finally(() => {
          setTimeout(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          }, 1500);
        });
    },
    async initTableContent() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_STRUCTURE, {});
      await this.updateTableData();
    },
    setPageLoading(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    async updateTableData() {
      await this.$store.dispatch(this.table_state.UPDATE_TABLE_DATA, {
        ...this.$store.getters[this.store_names.getTableState],
        warehouse_id: this.selectedWarehouse,
      });
    },
    makeCount(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$store.commit(SET_ACTION_TYPE, "scan");
      this.$refs.scanItem.toggleModal();
    },
    makeRecount(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$store.commit(SET_ACTION_TYPE, "recount");
      this.$refs.assignUser.toggleModal();
    },
    assignUser(item) {
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$store.commit(SET_ACTION_TYPE, "assign");
      this.$refs.assignUser.toggleModal();
    },
    exportTasks(item) {
      this.$store.dispatch(UPDATE_EXPORT_TASKS, item.id).then(() => {
        const link = document.createElement("a");
        link.href = this.$store.getters.getCYCLECOUNTSTaskExportUrl;
        link.download = "Export.csv";
        link.click();
        setTimeout(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        }, 1500);
        link.remove();
      });
    },
    closeTask(item) {
      if (!item) {
        return;
      }
      SwalService.warningConditionMessage(
        {
          title: SwalService.titles.are_you_sure,
          html: SwalService.messages.you_wont_be_able_to_revert_this,
        },
        () => {
          this.setPageLoading(true);
          const payload = {
            warehouse_id: this.selectedWarehouse,
            cycle_count_number: item.cycle_count_number,
          };
          this.$store
            .dispatch(CLOSE_CYCLE_COUNT, payload)
            .then(() => {
              Swal.fire({
                title: "Success",
                icon: "success",
                text: `Cycle Count Task Closed`,
                timer: 2500,
              });
            })
            .then(() => {
              this.$store.dispatch(UPDATE_TABLE_DATA, {
                ...this.table_state,
                warehouse_id: this.selectedWarehouse,
              });
            });
        }
      );
    },
    confirmCount(item) {
      this.item = item;
      setTimeout(() => {
        this.$refs.cycleCount.toggleModal();
      }, 200);
    },
  },
  computed: {
    isDataLoaded: function() {
      return !!this.$store.getters[this.store_names.getTableData];
    },
    selectedWarehouse: function() {
      return this.$store.getters.getSelectedWarehouse;
    },
    actions: function() {
      return [
        {
          name: "count",
          title: "Count",
          type: "normal",
          method: "makeCount",
          isVisible: true,
        },
        {
          name: "recount",
          title: "Recount",
          type: "normal",
          method: "makeRecount",
          isVisible: true,
        },
        {
          name: "assign_user",
          title: "Assign User",
          type: "normal",
          method: "assignUser",
          isVisible: true,
        },
        {
          name: "export_tasks",
          title: "Export Tasks",
          type: "normal",
          method: "exportTasks",
          isVisible: true,
        },
        {
          name: "close",
          title: "Close",
          type: "normal",
          method: "closeTask",
          isVisible: true,
        },
        {
          name: "scanned_items",
          title: "Scanned items",
          type: "normal",
          method: "confirmCount",
          isVisible: true,
        },
      ];
    },
  },
  beforeMount() {
    if (this.selectedWarehouse) {
      this.$store.commit(SET_PAGE_LOADING, true);
      this.initTableContent();
    }
  },
  watch: {
    selectedWarehouse: function(value) {
      if (value) {
        this.setPageLoading(true);
        this.initTableContent();
      }
    },
  },
};
</script>
