<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Download QC Document</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <div>
          <v-select
            v-model="formData.group_by"
            label="Group by"
            item-text="name"
            item-value="index"
            :items="items"
            clearable
            dense
            outlined
            :error-messages="group_byErrors"
            @input="$v.formData.group_by.$touch()"
            @blur="$v.formData.group_by.$touch()"
          ></v-select>
        </div>

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click="submit"
            type="submit"
            class="btn btn--export-filter mr-3 px-5 py-3 ls1"
          >
            Download
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  // requiredIf,
} from "vuelidate/lib/validators";

export default {
  name: "DownloadQcDocument",
  mixins: [validationMixin],
  props: ["pageLoading", "functionForDownload"],
  validations: {
    formData: {
      group_by: { required },
    },
  },

  data: () => ({
    dialog: false,
    formData: {
      group_by: null,
    },
  }),
  methods: {
    toggleModal() {
      if (this.dialog) {
        this.clearData();
      }
      this.dialog = !this.dialog;
    },

    clearData() {
      this.formData = {
        group_by: null,
      };
      this.$v.$reset();
    },
    handleFormValidation(fieldName) {
      const errors = [];
      if (!this.$v.formData[fieldName].$dirty) return errors;
      if ("required" in this.$v.formData[fieldName]) {
        !this.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      this.pageLoading(true);

      const payload = {
        id: this.item.id,
        ...this.formData,
      };
      this.functionForDownload(payload);
      this.toggleModal();
    },
  },
  computed: {
    items: function () {
      return this.$store.getters.getCYCLECOUNTSTableStructure.group_data;
    },
    group_byErrors: function () {
      return this.handleFormValidation("group_by");
    },
    item: function () {
      return this.$store.getters.getItemForAction;
    },
  },
};
</script>
