var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDataLoaded)?_c('div',{staticClass:"p-4 bg-white rounded-lg"},[_c('DataTable',{attrs:{"table_state":_vm.table_state,"store_names":_vm.store_names,"isFilterRequiredForExport":true,"isWarehouseRequired":true,"skipAddButton":true,"overrideActionsWith":_vm.actions,"recordDetailComponent":_vm.cycleCountDetails},on:{"makeRecount":_vm.makeRecount,"assignUser":_vm.assignUser,"exportTasks":_vm.exportTasks,"closeTask":_vm.closeTask,"makeCount":_vm.makeCount,"confirmCount":_vm.confirmCount},scopedSlots:_vm._u([{key:"topActions",fn:function({
        permissions,
        updateTable,
        pageLoader,
        selectedItems,
        clearSelected,
      }){return [_c('div',{staticClass:"mr-md-2 mr-sm-1 mb-1 mr-0 pa-0",class:{ 'col-6 px-8 mt-3': _vm.$vuetify.breakpoint.xsOnly }},[_c('ActionsButton',{attrs:{"permissions":permissions,"refresher":updateTable,"pageLoader":pageLoader,"selectedItems":selectedItems,"clearSelected":clearSelected}})],1)]}},{key:"rowActionsDropdown",fn:function({
        permissions,
        pageLoader,
        item,
        updateTable,
      }){return [_c('v-btn',{staticClass:"d-block w-100",attrs:{"disabled":!item.extra_data.detail.actions.download_cc_document,"text":""},on:{"click":() => {
            _vm.handleDownloadAction(item);
          }}},[_vm._v("Download document ")])]}}],null,false,3561889620)}),_c('AssignUser',{ref:"assignUser",attrs:{"page-loading":_vm.setPageLoading}}),_c('ActionsComponent',{ref:"scanItem"}),_c('ConfirmCycleCount',{ref:"cycleCount",attrs:{"item":_vm.item,"headers":_vm.confirmItemsHeaders,"title":"cycle count","responseObject":"scanned_items","getItemsUrl":"cycle_counts/all-scanned-items","confirmItemsUrl":"cycle_counts/confirm-scanned-items","removeItemsUrl":"cycle_counts/remove-scanned-items","updateTable":_vm.updateTableData}}),_c('DownloadDocument',{ref:"downloadComponent",attrs:{"pageLoading":_vm.setPageLoading,"functionForDownload":_vm.downloadCCDocument}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }